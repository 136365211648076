.Surface {
	background: white;
	box-sizing: border-box;
	border-radius: var(--container-border-radius);
	box-shadow: var(--container-shadow);
	transition: all 0.3s ease-in;
}

.SurfacePaddingXs {
	padding: var(--container-padding-xs);
}

.SurfacePaddingSm {
	padding: var(--container-padding-sm);
}

.SurfacePaddingMd {
	padding: var(--container-padding-md);
}

.SurfacePaddingLg {
	padding: var(--container-padding-lg);
}

.SurfacePaddingNone {
	padding: 0;
}

.SurfaceBorderRadiusNone {
	border-radius: 0;
}

.SurfaceBorderSubdued {
	border: 1px solid var(--base-color-lightest);
}

.SurfaceBorderDashed {
	border: 2px dashed var(--color-grey-5);
}

.SurfaceShadowNone {
	box-shadow: none;
}

.SurfaceHoverShadow:hover {
	box-shadow: var(--container-shadow);
}

.SurfaceColorRed {
	background-color: var(--surface-color-red);
}

.SurfaceColorYellow {
	background-color: var(--surface-color-yellow);
}

.SurfaceColorOrange {
	background-color: var(--surface-color-orange);
}

.SurfaceColorGreen {
	background-color: var(--surface-color-green);
}

.SurfaceColorGrey {
	background-color: var(--surface-color-grey);
}

.SurfaceColorPurple {
	background-color: var(--surface-color-purple);
}

.SurfaceColorDarkBlue {
	background-color: var(--surface-color-dark-blue);
}

.SurfaceColorBlue {
	background-color: var(--surface-color-blue);
}

.SurfaceColorLightBlue {
	background-color: var(--surface-color-light-blue);
}

.SurfaceColorBlurple {
	background-color: var(--surface-color-blurple);
}

.SurfaceColorWhite {
	background-color: var(--surface-color-white);
}

.SurfaceColorPink {
	background-color: var(--surface-color-pink);
}

.SurfaceColorLightGrey {
	background-color: var(--surface-color-light-grey);
}

.SurfaceColorSkyBlue {
	background-color: var(--surface-color-sky-blue);
}

.SurfaceHoverColorRed:hover {
	background-color: var(--surface-hover-color-red);
}

.SurfaceHoverColorYellow:hover {
	background-color: var(--surface-hover-color-yellow);
}

.SurfaceHoverColorOrange:hover {
	background-color: var(--surface-hover-color-orange);
}

.SurfaceHoverColorGreen:hover {
	background-color: var(--surface-hover-color-green);
}

.SurfaceHoverColorGrey:hover {
	background-color: var(--surface-hover-color-grey);
}

.SurfaceHoverColorPurple:hover {
	background-color: var(--surface-hover-color-purple);
}

.SurfaceHoverColorDarkBlue:hover {
	background-color: var(--surface-hover-color-dark-blue);
}

.SurfaceHoverColorBlue:hover {
	background-color: var(--surface-hover-color-blue);
}

.SurfaceHoverColorLightBlue:hover {
	background-color: var(--surface-hover-color-light-blue);
}

.SurfaceHoverColorBlurple:hover {
	background-color: var(--surface-hover-color-blurple);
}

.SurfaceHoverColorPink:hover {
	background-color: var(--surface-hover-color-pink);
}
