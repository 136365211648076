.PageMarginLg {
	margin: 0 2rem 2rem 2rem;
}

.PageMarginSm {
	margin: 0 1rem 1rem 1rem;
}

.PageMarginNone {
	margin: 0;
}

.PageHeading {
	grid-area: heading;
	margin-bottom: 1.5rem;
}

.PageSubHeading {
	grid-area: subheading;
	margin-bottom: 1.5rem;
	display: flex;
}

.PageSubHeadingSecondary {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.PageContent {
	grid-area: content;
}

.PageSidebar {
	grid-area: sidebar;
	display: flex;
	flex-direction: column;
}

.PageWithSidebar {
	display: grid;
	grid-template-areas:
		'heading heading'
		'subheading .'
		'content sidebar';
	column-gap: 1.75rem;
	grid-template-columns: minmax(0, 1fr) 20rem;
}

.PageSidebarSizeWide {
	grid-template-columns: minmax(0, 1fr) 25rem;
}

.PageSidebarSideLeft {
	grid-template-areas:
		'heading heading'
		'subheading .'
		'sidebar content';
	grid-template-columns: 20rem minmax(0, 1fr);
}

.PageTopMargin {
	margin-top: var(--spacing-6);
}

.PageTopHeaderBarMobile {
	background-color: var(--color-white);
	gap: 0;
	border-bottom: 1px solid var(--color-grey-5);
	width: auto;
	padding: var(--spacing-5) var(--spacing-6);
}

.PageTopHeaderBarCenterTitle {
	margin: 0 auto;
}
